import { writable, Writable } from "svelte/store";

interface StoreProps {
  title: string;
  message?: string;
  confirmButtonText: string;
  enabled: boolean;
  onConfirm: () => Promise<void>;
  onClose?: () => void;
}

interface ConfirmationStore extends Writable<StoreProps> {
  showConfirmation(title: string, message: string, confirmButtonText: string, onConfirm: () => Promise<void>, onClose?: () => void): void;
  hideConfirmation(): void;
  disable(): void;
  enable(): void;
}

// This is a singletone store...
const store = createStore();

export function getConfirmationStore(): ConfirmationStore {
  return store;
}

function createStore(): ConfirmationStore {
  const store = writable<StoreProps>({ title: "", onConfirm: async () => {}, confirmButtonText: "Ok", enabled: true });
  return {
    subscribe: store.subscribe,
    set: store.set,
    update: store.update,
    hideConfirmation() {
      store.update((value) => {
        return {
          enabled: true,
          title: "",
          confirmButtonText: "Ok",
          onClose: () => {},
          onConfirm: async () => {}
        };
      });
    },
    showConfirmation(title: string, message: string, confirmButtonText: string, onConfirm: () => Promise<void>, onClose: () => void) {
      store.update((value) => {
        return {
          enabled: true,
          title,
          message,
          confirmButtonText,
          onConfirm,
          onClose
        };
      });
    },
    disable() {
      store.update((value) => {
        return {
          ...value,
          enabled: false
        };
      });
    },
    enable() {
      console.log("enabling confirmation store...");
      store.update((value) => {
        return {
          ...value,
          enabled: true
        };
      });
    }
  };
}
